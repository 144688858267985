import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-10.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/GuideFromAtoZ`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Digipay Guide from A-Z',
  },
  {
    property: 'og:description',
    content: 'Lahat ng kailangan mong malaman sa Digipay app, narito na!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="Digipay Guide From A-Z" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          DIGIPAY GUIDE FROM A-Z
        </Heading>
        <Text className="blog__title-tagline">
          Lahat ng kailangan mong malaman sa Digipay app, narito na!
        </Text>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">A</span>IRLINES
          </Heading>
          <Text>
            Set your #TravelGoals dahil pwede kang magbayad ng airline bookings
            mo through Digipay!
          </Text>
          <div className="blog__two-columned-list">
            <ul>
              <li>Air Asia</li>
              <li>Cebu Pacific</li>
              <li>Philippine Airlines (PAL)</li>
              <li>Zest Air</li>
              <li>Tiger Air</li>
              <li>Sky Jet</li>
              <li>VIA.com</li>
              <li>Jet Star Airlines</li>
            </ul>
          </div>
          <Text className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">B</span>ILLERS
          </Heading>
          <Text>
            Due na naman ang bills? No worries dahil over 300 billers ang
            partner ng Digipay. Kaya kahit sabay-sabay sila, pwede mo nang
            bayaran in just one stop. Hanapin lang ang pinakamalapit na Digipay
            Agent.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">C</span>REDIT CARD AND
            CABLE
          </Heading>
          <Text>
            Gawing convenient and hassle-free ang pagbabayad mo ng monthly
            bills.
          </Text>
          <p className="blog__list-title">Accredited Bank Billers</p>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Allied Bank</li>
              <li>Asia United Bank Credit Cards</li>
              <li>Bankard MC/Visa/JCB</li>
              <li>BPI Credit Cards</li>
              <li>BDO</li>
              <li>Chinabank Credit Card</li>
              <li>CitiBank MC/Visa</li>
              <li>CTBC Bank Visa Cards</li>
              <li>EastWest Bank</li>
            </ul>
            <ul xs={12} md={6}>
              <li>HSBC</li>
              <li>Maybank Credit Card</li>
              <li>Metrobank Credit Card</li>
              <li>PNB</li>
              <li>Security Bank Diners</li>
              <li>Security Bank Mastercard</li>
              <li>Security Bank Mastercard</li>
              <li>UnionBank Visa Credit</li>
            </ul>
          </div>

          <p className="blog__list-title">Accredited Cable Billers</p>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Angeles City Cable Television</li>
              <li>Bicore One Network Ent. Inc.</li>
              <li>Cable Link</li>
              <li>Cable Television Network Inc.</li>
              <li>Cignal TV</li>
              <li>Converge Pure Fiber Technology</li>
              <li>Central Luzon Cable Television Network</li>
              <li>Comclark Network & Technology Corp.</li>
              <li>DCTV Cable</li>
              <li>H.M. CATV Inc.</li>
              <li>Home Lipa Cable Inc.</li>
              <li>Hi-Tech Cable</li>
              <li>Golden Eagle Cable</li>
              <li>JMD Cable Network</li>
            </ul>
            <ul xs={12} md={6}>
              <li>Primecast Cable TV Network</li>
              <li>San Clemente Cable</li>
              <li>South Luzon CATV</li>
              <li>JMY Advantage</li>
              <li>Leisure World</li>
              <li>Met Cable</li>
              <li>Northstar Cable</li>
              <li>Planet Cable Plus Cable</li>
              <li>Pilipino Cable</li>
              <li>Royal Cable</li>
              <li>SkyCable</li>
              <li>Verdant Cable</li>
              <li>Suburban Cable Network</li>
            </ul>
          </div>

          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">D</span>OWNLOAD THE
            DIGIPAY APP
          </Heading>

          <Text>
            To kickstart your all-in-one Digitindahan, i-
            <a
              className="inline-link"
              href="https://play.google.com/store/apps/details?id=com.fsgcapital.digipay&hl=en"
            >
              download
            </a>{' '}
            ang Digipay app mula sa Google Playstore.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">E</span>ASY SIGN-UP
          </Heading>
          <Text>
            Madali lang magsign-up as Digipay Agent! Sa loob lamang ng 24 oras
            ay maaari nang ma-activate ang iyong account. Click{' '}
            <a
              className="inline-link"
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            >
              here
            </a>{' '}
            to sign-up.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">F</span>OR JUST P100,
            MAY NEGOSYO KA NA
          </Heading>
          <Text>
            Kung gusto mo ng extra income pero affordable ang puhunan, Digipay
            lang ang katapat niyan!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">G</span>OVERNMENT
            SERVICES
          </Heading>
          <Text>Pati government bills, pwedeng bayaran sa Digipay!</Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>NBI Clearance Payment</li>
              <li>NSO - Helpline Plus</li>
              <li>PAG-IBIG Fund</li>
              <li>PAG-IBIG Housing Loan</li>
              <li>PAG-IBIG: MP2 (500)</li>
            </ul>
            <ul xs={12} md={6}>
              <li>PEZA</li>
              <li>POEA</li>
              <li>PRC</li>
              <li>TIEZA</li>
            </ul>
          </div>

          <p className="blog__notes">
            {' '}
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">H</span>EALTHGUARD
          </Heading>
          <Text>
            Nandito na ang health insurance na abot-kaya sa halagang P100 lang.
            Click this{' '}
            <a className="inline-link" href="https://healthguard.digipay.ph/">
              link
            </a>{' '}
            to know more about HealthGuard.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">I</span>NSURANCE AND
            FINANCE SERVICES
          </Heading>
          <Text>
            Hindi lang Home Credit, Aeon, at Cashalo ang mababayaran mo through
            Digipay. Here’s the full list of billers for your insurance and
            finance payments:
          </Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Aeon Credit</li>
              <li>Home Credit</li>
              <li>Cashalo</li>
              <li>Gloriosa Finance</li>
              <li>Air You Go Travels</li>
              <li>Asialink</li>
              <li>AXA Philippines</li>
              <li>Cocolife Insurance</li>
              <li>Connext Marketing Inc.</li>
              <li>Fuse Lending</li>
              <li>GRAB</li>
              <li>Paramount Insurance</li>
              <li>Chinatrust</li>
              <li>Dragon Loans</li>
              <li>Easycash Lending Company</li>
              <li>Enterprise Bank Loans</li>
              <li>Finaswide Credit Corporation</li>
              <li>Maybridge Financial</li>
              <li>Norkis Financial</li>
            </ul>
            <ul xs={12} md={6}>
              <li>First MFI Network</li>
              <li>Global Dominion Financing Inc.</li>
              <li>iCash Express Lending Corp.</li>
              <li>PSBank</li>
              <li>RBDI</li>
              <li>Pera Agad</li>
              <li>Paramount Life</li>
              <li>South Asialink</li>
              <li>Tagum Cooperative</li>
              <li>Unistar Credit Finance</li>
              <li>Tiaong Rural Bank Standard Chartered EZ Loan</li>
              <li>PhilAm Life</li>
              <li>Radiowealth Finance</li>
              <li>RS Property Management Corp.</li>
              <li>Insular Savers Bank, Inc.</li>
              <li>Lipa Bank Inc.</li>
              <li>Loan Star Lending Group</li>
              <li>Prudential Life</li>
              <li>Sumisho Motor Loan</li>
            </ul>
          </div>
          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">J</span>OIN DIGIPAY
            AGENT GROUP
          </Heading>
          <Text>
            Para laging updated sa announcements and promos, all Digipay Agents
            are encouraged to join{' '}
            <a
              className="inline-link"
              href="https://www.facebook.com/groups/Digipayagents/"
            >
              Digipay Agent Group
            </a>{' '}
            on Facebook.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">K</span>URYENTE
          </Heading>
          <Text>
            Nakapag-bayad ka na ba ng Meralco through Digipay? Ang convenient,
            ‘diba? Narito pa ang ibang accredited power billers:
          </Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Angeles Electric Company (AEC)</li>
              <li>Batangas (BATELEC 1)</li>
              <li>Batangas (BATELEC 2)</li>
              <li>Benguet (BENECO)</li>
              <li>Camarines Sur (CASURECO2)</li>
              <li>Laguna (FLECO)</li>
              <li>Ilocos Norte (INEC)</li>
              <li>Ilocos Sur (ISECO)</li>
              <li>Lima Enerzone</li>
              <li>Meralco</li>
              <li>Meralco Kload</li>
              <li>Nueva Ecija (NEECO2A1)</li>
              <li>Nueva Ecija (NEECO2A2)</li>
              <li>Olongapo (OEDC)</li>
            </ul>
            <ul xs={12} md={6}>
              <li>Palawan Electric Cooperative</li>
              <li>Pampanga (PELCO2)</li>
              <li>Pampanga (PELCO3)</li>
              <li>Pampanga (PRESCO)</li>
              <li>Pangasinan (PANELCO1)</li>
              <li>Pangasinan (PANELCO3)</li>
              <li>Peninsula (PENELCO)</li>
              <li>Quezon (QUEZELCO2)</li>
              <li>San Jose City (SAJELCO)</li>
              <li>Subic (SEZ)</li>
              <li>Sorsogon (SORECO2)</li>
              <li>Zambales (ZAMECO1)</li>
              <li>Zambales (ZAMECO2)</li>
            </ul>
          </div>
          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">L</span>IVE CHAT SUPPORT
          </Heading>
          <Text>
            Kung meron kang questions and concerns, nakaabang ang Sales Support
            Team ng Digipay mula Lunes hanggang Biyernes, 8AM-7PM, at Sabado,
            9AM-6PM.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">M</span>OBILE MONEY
          </Heading>
          <Text>
            Need na ba ng allowance ni bunso? Magpadala na ng pera sa GCash,
            PayMaya at Smart Money through Digipay.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">N</span>O TRAFFIC, NO
            PILA
          </Heading>
          <Text>
            ‘Di mo na kailangang mamasahe para lang makapag-bayad ng bills.
            Walang traffic, walang pila. Maging ka-Digipay na!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">O</span>N-THE-GO
          </Heading>
          <Text>
            Digipay is your online walking tindahan. Kahit saan ka magpunta,
            pwede kang kumita basta may internet at cellphone ka. Ito ang
            negosyong ON-THE-GO!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">P</span>AYMENT GATEWAY
          </Heading>
          <Text>
            Nagshopping ka ba online? Tumatanggap din ang Digipay ng Dragon Pay
            at Pesopay payments.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">Q</span>UICK RETURN OF
            INVESTMENT
          </Heading>
          <Text>
            Bawat transaction mo, may rebates kang makukuha! Basta mayroon kang
            sipag at tiyaga, ‘di mo namamalayan palaki na nang palaki ang kita
            mo sa Digipay. Agree ka ba?
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">R</span>EFERRAL FEE
          </Heading>
          <Text>
            Kapag nagrefer ka ng friend gamit ang iyong code, you’ll get 100
            credits. Instant extra income!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">S</span>CHOOL
          </Heading>
          <Text>
            Tuition fees ng mga anak? I-Digipay mo na! Narito ang listahan ng
            accredited school billers:
          </Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Immaculate Concepcion School of Baliuag</li>
              <li>Private Education Assistance Committee</li>
              <li>PhilSmile</li>
              <li>Messiah School of Foundation, Inc.</li>
              <li>Mother of Divine Providence School</li>
              <li>Essential School</li>
              <li>Microlink Institute of Science and Technology</li>
              <li>Mary Help of Christians College</li>
            </ul>
            <ul xs={12} md={6}>
              <li>Oblates of Saint Joseph Schools</li>
              <li>Academia de San Lorenzo</li>
              <li>AMG Skilled Hands Technological College</li>
              <li>Springfield of Novaliches</li>
              <li>St. Gertrude School of Novaliches</li>
              <li>Manila Adventist College</li>
              <li>Sophia School</li>
              <li>Our Lady of Guadalupe College</li>
            </ul>
          </div>
          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">T</span>ELECOM
          </Heading>
          <Text>
            Halos lahat kailangan ng pang-call, pang-text at internet kaya
            siguradong madami kang suki sa pagbebenta ng load through Digipay!
          </Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Globe Telecom</li>
              <li>Smart Communications Inc.</li>
              <li>Sun Cellular</li>
              <li>Globelines/Innove</li>
            </ul>
            <ul xs={12} md={6}>
              <li>Globe Handyphone</li>
              <li>Bayantel</li>
              <li>PLDT</li>
              <li>Digitel</li>
            </ul>
          </div>
          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">U</span>SER-FRIENDLY APP
          </Heading>
          <Text>
            Take it from our Digipay Agents, user-friendly talaga ang Digipay
            app kaya madaling i-navigate at maintindihan ang bawat nilalaman
            nito.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">V</span>ISIT OUR FB & IG
            PAGE
          </Heading>
          <Text>
            We’d love to hear from you. I-share mo na ang thoughts at reactions
            mo tungkol sa Digipay. Like and follow us on{' '}
            <a
              className="inline-link"
              href="https://www.facebook.com/digipayph/"
            >
              Facebook
            </a>{' '}
            and{' '}
            <a
              className="inline-link"
              href="https://www.instagram.com/digipayph/"
            >
              Instagram
            </a>
            .
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">W</span>ATER
          </Heading>
          <Text>
            Bukod sa kuryente, isa pa ‘tong kailangang bayaran buwan-buwan.
            Imagine buong barangay sa’yo na nagbabayad ng water bills nila.
            Nakaka-excite ‘di ba?
          </Text>
          <div className="blog__two-columned-list">
            <ul xs={12} md={6}>
              <li>Manila Water</li>
              <li>Maynilad Water Services Inc.</li>
              <li>Batangas Water District</li>
              <li>BP Water Works, Inc.</li>
              <li>Cabanatuan City Water District</li>
              <li>Camarines Norte Water District</li>
              <li>Camiling Water District</li>
              <li>Clark Water</li>
              <li>San Jose del Monte Water District</li>
              <li>Daraga Water District</li>
              <li>Prime Infrastructure Corp.</li>
              <li>Manila Water Philippine Ventures</li>
              <li>Marilao Water District</li>
            </ul>
            <ul xs={12} md={6}>
              <li>First Peak</li>
              <li>Obando Water District</li>
              <li>Orani Water District</li>
              <li>Meycauayan Water DistrictTabaco City Water District</li>
              <li>Water PhilippinesGood Hands Water Specialist, Inc.</li>
              <li>Guagua Water District</li>
              <li>Happy Well Mngt. & Collection Services, Inc.</li>
              <li>Ilagan Water District</li>
              <li>Laguna Water</li>
              <li>Laguna Water District Aquatech R.C.</li>
              <li>Mabalacat Water District</li>
              <li>Sta. Lucia Realty & Development Inc.</li>
            </ul>
          </div>
          <p className="blog__notes">
            Note: We are in the process of migrating our Bills Payment platform
            and some billers may not be available.
          </p>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">X</span>TRA INCOME, HERE
            I COME!
          </Heading>
          <Text>
            Kung mayroon kang sari-sari store, level it up! Kung nasa bahay ka
            lang, bakit hindi mo i-try mag-negosyo? Isa lang ang naman ang
            sigurado when you sign-up with Digipay: magkakaroon ka talaga ng
            extra income.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">Y</span>OU
          </Heading>
          <Text>
            Ikaw. Yes, ka-Digipay, ikaw! Whether you’re a Digipay Suki, a
            Digipay Agent, o interesado pa lang magsign-up, nagpapasalamat kami
            sa’yo. We thank YOU for the trust and support.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            <span className="blog__styled-title-letter">Z</span>A TOTOO LANG,
            DITO NA NAGTATAPOS ANG GUIDE NA ITO. 😅😅😅
          </Heading>
        </Section>

        <p>Hanggang sa muli, ka-Digipay!</p>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
